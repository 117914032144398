/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of, throwError } from 'rxjs';
import {
  filter,
  mergeMap,
  retryWhen,
  concatMap,
  delay,
  map
} from 'rxjs/operators';
import { LoginService } from './login.service';
import { getReason, ERROR_REASON, AUTHENTICATED } from '@core/http';
import { WHITE_LISTED_DOMAINS } from './auth.constants';

export const RETRY_COUNT = 2;
export const RETRY_WAIT_MILLISECONDS = 500;
export const ERROR_CODES_TO_RETRY = [401];
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public auth: LoginService, public router: Router) {}

  private requestQueue = new Map<string, HttpRequest<any>>();

  private get isAuthRefreshInProgress() {
    return this.auth.refreshInProgress;
  }

  private logoutAndRedirect() {
    this.auth.logout();
  }

  private getAuthRequest(req: HttpRequest<any>) {
    return req.clone({
      url: req.url,
      setHeaders: {
        Authorization: `Bearer ${this.auth.idToken}`
      }
    });
  }

  private retryQueuedRequests(next: HttpHandler) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_key, value] of this.requestQueue) {
      const retry = this.getAuthRequest(value)
      console.log('retry of', value, 'with', retry);
      next.handle(retry);
    }

    this.requestQueue = new Map();
  }

  private refreshToken(
    error: HttpErrorResponse,
    next: HttpHandler
  ): Observable<any> {
    if (this.isAuthRefreshInProgress.getValue()) {
      console.log('refresh in progress');
      return of();
    }

    return from(this.auth.tryToRefreshToken()).pipe(
      map((isRefreshedSuccessfully) => {
        console.log('is refresh success', isRefreshedSuccessfully);
        if (!isRefreshedSuccessfully) {
          this.logoutAndRedirect();
          return of();
        }
        this.retryQueuedRequests(next);

        return of({ ...error, error: { reason: ERROR_REASON.RETRY } });
      })
    );
  }

  private checkAuthorisation(
    authRequest: HttpRequest<any>,
    next: HttpHandler,
    error: HttpErrorResponse,
    reason: ERROR_REASON | string | undefined
  ) {
    console.log('check auth', reason);
    if (reason === ERROR_REASON.TOKENREFRESH) {
      if (this.isAuthRefreshInProgress.getValue()) {
        if (!this.requestQueue.has(authRequest.url)) {
          console.log('check auth - re-adding request to queue');
          this.requestQueue.set(authRequest.url, authRequest);
        }
      }
      console.log('doing refresh');
      return this.refreshToken(error, next);
    }
    this.logoutAndRedirect();
    return throwError(error);
  }

  private isDomainInWhiteLists(req: HttpRequest<any>) {
    let whiteListed = false;

    for (const domain of WHITE_LISTED_DOMAINS) {
      if (req.url.includes(domain)) {
        whiteListed = true;
        break;
      }
    }

    return whiteListed;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const getAnonimusUrlRegexp = /get-anonymous-token/;
    if (getAnonimusUrlRegexp.test(req.url)) {
      return next.handle(req);
    }

    return this.auth.isReady.pipe(
      filter((isReady: boolean) => {
        return isReady === true;
      }),
      mergeMap(() => {
        if (!this.auth.isTokenValidByTime) {
          return this.checkAuthorisation(
            req,
            next,
            new HttpErrorResponse({}),
            ERROR_REASON.TOKENREFRESH
          );
        }

        const isAuthenticated = req.context.get(AUTHENTICATED);

        if (!isAuthenticated || !this.isDomainInWhiteLists(req)) {
          return next.handle(req);
        }
        const request: HttpRequest<any> = this.getAuthRequest(req);
        return next.handle(request).pipe(
          retryWhen((error: Observable<HttpErrorResponse>) =>
            error.pipe(
              concatMap((error, count) => {
                const isHttpError = error instanceof HttpErrorResponse;
                const reason = getReason(error);

                if (!this.auth.isTokenValidByTime) {
                  return this.checkAuthorisation(
                    req,
                    next,
                    error,
                    ERROR_REASON.TOKENREFRESH
                  );
                }

                if (!isHttpError) {
                  return throwError(error);
                }
                console.log('http error retry', count, 'of', RETRY_COUNT);
                const shouldRetry =
                  count <= RETRY_COUNT &&
                  ERROR_CODES_TO_RETRY.includes(error.status) &&
                  !reason;

                if (shouldRetry) {
                  return of(error);
                }

                if (error.status === 401) {
                  if (count > RETRY_COUNT) {
                    location.reload()
                  } else {
                    return this.checkAuthorisation(req, next, error, reason);
                  }
                }
                return throwError(error);
              }),
              delay(RETRY_WAIT_MILLISECONDS)
            )
          )
        );
      })
    );
  }
}
