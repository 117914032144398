import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';

import IComment, { CommentVisibility } from '@models/comment';
import { createContentUrl, createProfileUrl } from '@utils/urlFactory';
import { HttpService } from '@core/http/http.service';
import {
  CommentsStatsByUser
} from '@modules/admin-access-modules/comments/components/comments-by-user-export/comments-by-user-export.types';
import {
  ICommentListQuery, ICommentLocation,
  ICommentSearchResponse,
} from '@modules/admin-access-modules/comments/components/comments-list/comments-list.types';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  cachedCommentData: { [key: string]: IComment[] } = {};

  constructor(private client: HttpService<IComment>) {}

  public getComments = (
    chapterId: string,
    sectionId: string,
    forceReload = false
  ): Observable<IComment[]> => {
    const CACHE_KEY = `${sectionId}_${chapterId}`;
    if (this.cachedCommentData[CACHE_KEY] && !forceReload) {
      return of(this.cachedCommentData[CACHE_KEY]);
    } else {
      const path = ['comment', 'chapter', chapterId];
      if (sectionId) {
        path.push('section', sectionId);
      }
      return this.client.get<IComment[]>(createProfileUrl(...path)).pipe(
        map((comments) => {
          this.cachedCommentData[CACHE_KEY] = comments;
          return this.cachedCommentData[CACHE_KEY];
        }),
        share()
      );
    }
  };

  public postComment = (
    chapterId: string,
    sectionId: string,
    comment: IComment
  ): Observable<IComment> => {
    const path = ['comment', 'chapter', chapterId];
    if (sectionId) {
      path.push('section', sectionId);
    }

    return this.client
      .post<IComment>(createProfileUrl(...path), comment)
      .pipe(share());
  };

  public postReply = (
    commentId: string,
    comment: IComment
  ): Observable<IComment> => {
    return this.client
      .post<IComment>(createProfileUrl('comment', commentId, 'reply'), comment)
      .pipe(share());
  };

  public setVisibility = (
    commentId: string,
    visibility: CommentVisibility
  ): Observable<IComment> => {
    const path = createProfileUrl(
      'comment',
      commentId,
      'visibility',
      visibility as unknown as string
    );
    return this.client.get<IComment>(path).pipe(share());
  };

  public modifyComment(commentId: string, dataToBeModify: { _text: string }) {
    const path = createProfileUrl('comment', commentId);
    return this.client.put<IComment>(path, dataToBeModify).pipe(share());
  }

  public deleteComment(commentId: string) {
    return this.client
      .delete<IComment>(createProfileUrl('comment', commentId))
      .pipe(share());
  }

  public deleteReply(commentId: string, replyId: string) {
    return this.client
      .delete<IComment>(
        createProfileUrl('comment', commentId, 'reply', replyId)
      )
      .pipe(share());
  }

  public getCommentsByUserStat(dateFrom: string, dateTo: string): Observable<CommentsStatsByUser[]> {
    return this.client.get<CommentsStatsByUser[]>(createProfileUrl(
      'comment',
      'stats',
      dateFrom,
      dateTo,
    )).pipe(share());
  }

  public findComments(query: ICommentListQuery) {
    return this.client.post<ICommentSearchResponse>(
      createProfileUrl(
        'comment',
        'search'
      ),
      query,
    )
  }

  public getCommentUrl(id: string) {
    return this.client.get<ICommentLocation>(
      createContentUrl(
        'comment',
        'content-path',
        id,
      ),
    )
  }
}
